<template>
    <div class="container" ref="wrapper">
        <van-sticky>
            <van-nav-bar :title="title" left-arrow @click-left="$router.replace({ path: '/' })">

                <template #right>

                    <div @click="tochart()" style="margin: 5px;" v-if="roleType == 1 || roleType == 99 || roleType == 999">
                        <van-icon name="chart-trending-o" color="#1989fa" size="16"></van-icon>
                        <div style="float: right; margin-left: 5px; font-size: 15px">
                            管理视角统计
                        </div>
                    </div>
                </template>
            </van-nav-bar>
        </van-sticky>
        <van-tabs v-model="activeTab">
            <van-tab title="按品鉴师统计">
                <div class="tabcontent">
                    <HelpUserStat2></HelpUserStat2>
                </div>
            </van-tab>
            <van-tab title="活动明细">
                <div class="tabcontent">
                    <Campaign></Campaign>
                </div>
            </van-tab> 
        </van-tabs>


        <van-tabbar :route="true" v-model="active">
            <van-tabbar-item to="/dealer" icon="friends-o">经销商</van-tabbar-item>
            <van-tabbar-item to="/dealer/statindex" icon="bar-chart-o">统计</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
    
<script>
import { GetRoleType } from "@/api/dealer";
import Campaign from './components/Campaign.vue';
import HelpUserStat2 from './components/HelpUserStat2.vue';

//const key = "zn-history";
export default {
    name: "dealerList",
    components: {
        Campaign,
        HelpUserStat2,
    },
    data() {
        return {
            activeTab: 0,
            active: "",
            title: "统计信息",
            roleType: 0,
        };
    },
    activated() {
    },
    created() {
        this.initData();
    },
    mounted() { },

    methods: {
        async initData() {

            let roleResult = await GetRoleType();
            if (roleResult.data.success) {
                this.roleType = roleResult.data.data;
            }
            else {
                this.roleType = 0;
            }
        },
        
        tochart() {

const item = {
    name: "活动统计",
    url: "/grafana/d/0_BNN034z/pin-pai-ying-xiao-huo-dong-xiang-guan-qing-kuang-hui-zong?orgId=1&theme=light",
}
//hideMenu=true隐藏grafana菜单栏，hideHeader=true隐藏grafana头部，hideSubMenuControlsLink=true隐藏查看其他报表按钮
let url =
    item.url +
    "&hideMenu=true&hideHeader=true&hideSubMenuControlsLink=true";
// kiosk不传是默认、kiosk=tv、kiosk
//url += "&kiosk";
this.$router.push({
    path: "/frame?",
    query: {
        title: item.name,
        url: url,
    },
});
},
    },
};
</script>
    
<style lang="less" scoped>
.container {
    height: 100%;
    //overflow-y: auto;
    //box-sizing: border-box;

    /deep/.van-tabs {
        height: 100%;
    }

    /deep/.van-tabs__content {
        height: 100%;
    }
}

.tabcontent {
    height: 100%;
}

.van-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    /deep/ .van-tabs__wrap {
        height: 36px;
        padding-right: 36px;

        .van-tab {
            line-height: 36px;
        }

        .van-tabs__line {
            background-color: #3296fa;
            height: 2px;
        }
    }

    /deep/ .van-tabs__content {
        flex: 1;
        overflow: hidden;
    }

    /deep/ .van-tab__pane {
        height: 100%;

        .scroll-wrapper {
            height: 100%;
            overflow-y: auto;
        }
    }
}</style>
    