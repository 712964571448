<template>
    <div class="container" ref="wrapper">
      <div>
        <div>
          <van-dropdown-menu>
            <!-- 排序 -->
            <van-dropdown-item v-model="sorterType" :options="sorterTypeList" :title-class="sorterTypeTitleClass"
              @change="ChangeSorterType" />
  
  
            <!-- 类型菜单 -->
            <van-dropdown-item :title="filterTitle" :title-class="filterTitleClass" ref="filterRef">
  
              <div style=" margin: 0 auto; max-width: 750px; padding-top:15px">
  
                <van-field name="helpSale" v-model="page.filterData.helpSale" label="活动品鉴师" placeholder="活动品鉴师" />
                <van-field name="mainSale" v-model="page.filterData.mainSale" label="活动销售人员" placeholder="活动销售人员" />
                <van-field name="followSale" v-model="page.filterData.followSale" label="跟进销售" placeholder="跟进销售" />

  
                <van-field readonly clickable name="department" v-model="department" label="区域" placeholder="点击选择区域"
                  @click="showdepartment = true" />
  
                <van-popup v-model="showdepartment" closeable position="bottom" :style="{ height: '75%' }">
                  <van-nav-bar title="选择区域" />
                  <van-cell-group>
                    <van-tree-select :height="400" :items="ccitems" :active-id.sync="activeIds"
                      :main-active-index.sync="activeIndex" />
                  </van-cell-group>
                  <div style="margin: 16px; text-align: center;">
                    <van-button type="info" round block @click.prevent="changeDepartment">确认</van-button>
                  </div>
                </van-popup>
  
                <van-field readonly clickable name="area" :value="page.filterData.area" label="地区选择" placeholder="点击选择省市区"
                  @click="showArea = true" />
                <van-popup v-model="showArea" position="bottom">
                  <van-area :area-list="areaList" @confirm="onConfirmArea" @cancel="showArea = false"
                    :columns-placeholder="['请选择', '请选择', '请选择']" />
                </van-popup>
  
                <van-field name="radio" label="客户类型">
                  <template #input>
                    <van-radio-group v-model="dTypeName" direction="horizontal">
                      <van-radio name="经销商">经销商</van-radio>
                      <van-radio name="其它老板">其它老板</van-radio>
                      <van-radio name="团购客户">团购客户</van-radio>
                      <van-radio name="烟酒店">烟酒店</van-radio>
                      <van-radio name="终端店">终端店</van-radio>
                      <van-radio name="终端消费者">终端消费者</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
  
                <van-cell>活动日期筛选只影响统计数据</van-cell>
                <van-field name="radio" label="活动日期">
                  <template #input>
                    <van-radio-group v-model="dateRangeType" direction="horizontal">
                      <van-radio :name="1">3日内</van-radio>
                      <van-radio :name="2">7日内</van-radio>
                      <van-radio :name="3">10日内</van-radio>
                      <van-radio :name="4">15日内</van-radio>
                      <van-radio :name="5">30日内</van-radio>
                      <van-radio :name="6">3月内</van-radio>
                      <van-radio :name="7">6月内</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
  
  
                <van-field readonly clickable name="datetimePicker" :value="dateRange" label="或活动时间" placeholder="点击选择时间"
                  @click="showdateRange = true" />
                <van-calendar v-model="showdateRange" type="range" @confirm="onConfirmDateRange"
                  :min-date="new Date('2023/02/01')" :default-date="defaultdateRange" />
  
  
  
  
  
  
                <van-row style="margin: 20px;">
                  <van-col span="5" offset="1"> <van-button block round @click="clearFilter">
                      重置
                    </van-button></van-col>
                  <van-col span="16" offset="1"> <van-button type="info" block round @click="confirmFilter">
                      确认
                    </van-button></van-col>
                </van-row>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>
  
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">
            <van-cell-group>
              <van-cell v-for="item in dealers" :key="item.id.toString()" @click="toDetail(item.id.toString())">
                <h3 style="display: inline-block">{{ item.dealerName }}</h3>
                <span style="margin-left:15px">{{ item.dealerType }}</span>
                <van-row>
                  <van-col span="8">{{ item.center }}</van-col>
                  <van-col span="8">{{ item.department }}</van-col>
                  <van-col span="8">{{ item.city }}{{ item.county }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="24">活动总费用：{{ item.totalCost }}</van-col>
                  
                  
                </van-row>
                <van-row>
                  <van-col span="12">品鉴师：{{ item.helpSale }}</van-col>
                  <van-col span="12">销售人员：{{ item.mainSale }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="12">活动日期：{{ DateFromat(item.date) }}</van-col>
                  <van-col span="12">到场总人数：{{ item.comeCount }}</van-col>
                </van-row>
               

                <van-row>
                  <van-col span="8">服务终端数：{{ item.customerClintCount }}</van-col>
                  <van-col span="8">终端总人数：{{ item.dealerCustomerUserCount }}</van-col>
                  <van-col span="8">终端朋友数：{{ item.DealerCustomerCustomerCount }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="8">活动总费用：{{ item.totalCost }}</van-col>
                  <van-col span="8">餐费：{{ item.footCost }}</van-col>
                  <van-col span="8">用酒费用：{{ item.wineCost }}</van-col>
                </van-row>
                <van-row>
                  <van-col span="8">动销总金额：{{ item.orderAmount }}</van-col>
                  <van-col span="8">动销数：{{ item.orderCount }}</van-col>
                  <van-col span="8">动销产品数：{{ item.orderProductCount }}</van-col>
                  
                </van-row>
              </van-cell>
            </van-cell-group>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </template>
    
  <script>
  import { PageCampaignStat, GetRoleType } from "@/api/dealer";
  import { areaList } from '@vant/area-data';
  import { BrandDepartmentTree2 } from "@/utils/datetype";
  
  //const key = "zn-history";
  export default {
    name: "dealerList",
    components: {
    },
    data() {
      return {
        active: "",
        scroll: 0,
        refreshing: false,
        title: "经销商客户列表",
        upLoading: false,
        finished: false,
        dealers: [],
        showArea: false,
        areaList: areaList, // 数据格式见 Area 组件文档
        page: {
          current: 1,
          pageSize: 10,
          sorterType: 0,
          filterData: {
            name: "",
            area: "",
            department: "",
            followSale: "",
            helpSale: "",
            mainSale:"",
          },
        },
        sorterType: 0,
        activeIds: [],
        activeIndex: 0,
        ccitems: BrandDepartmentTree2,
        //排序
  
        sorterTypeList: [
          { text: '默认排序', value: 0 },
          { text: '动销金额', value: 1 },
          { text: '活动人数', value: 2 },
          { text: '总花费', value: 3 },
          
        ],
        showdateRange: false,
        dateRange: "",
        filterTitle: "筛选",
        filterTitleClass: "",
        sorterTypeTitleClass: "",
        dTypeName: "",
        dateRangeType: 0,
        defaultdateRange: [],
        showdepartment: false,
        department: "",
        roleType: 0,
      };
    },
    activated() {
      try {
        this.$refs.wrapper.scrollTop = this.scroll;
      } catch (error) {
        console.log(error);
      }
    },
    created() {
      const today = new Date();
      const lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7);
      this.defaultdateRange = [lastWeek, today];
      this.initData();
    },
    mounted() { },
  
    methods: {
        DateFromat(data) {
      if (!data) {
        return "";
      }

      return data.replace(" 00:00:00", "").replace("T00:00:00", "");
    },
      updatefilterData(filterData) {
  
        this.page = {
          current: 1,
          pageSize: 10,
          sorterType: 0,
          filterData: {
            ...filterData
          },
        };
        this.onSearch();
  
        //
  
      },
  
      changeDepartment() {
  
        this.department = this.activeIds.join(',');
        this.showdepartment = false;
      },
  
      clearFilter() {
        this.activeIds = [];
        this.activeIndex = 0;
        this.dateRangeType = 0;
        this.dateRange = "";
        this.dTypeName = "";
        this.department = "";
        this.page.filterData.area = "";
        this.page.filterData.department = "";
        this.page.filterData.followSale = ""
        this.page.filterData.helpSale="";
        this.page.filterData.mainSale="";
  
  
        // this.onSearch();
        // this.$refs.filterRef.toggle();
  
        this.filterTitle = "筛选";
        this.filterTitleClass = "";
  
  
      },
      confirmFilter() {
  
        if (
          this.page.filterData.area != "" ||
          this.page.filterData.followSale != "" ||
          this.page.filterData.helpSale != "" ||
          this.page.filterData.mainSale != "" ||
          this.department != "" ||
          this.dateRangeType != 0 ||
          this.dateRange != "" ||
          this.dTypeName != ""
  
        ) {
          this.filterTitle = "有筛选";
          this.filterTitleClass = "dropdownhasvalueTitle";
  
        }
  
        this.page.filterData.department = this.department;
        this.page.filterData.typeName = this.dTypeName;
        this.page.filterData.dateRange = this.dateRange;
        this.page.filterData.dateRangeType = this.dateRangeType;
        this.onSearch();
        this.$refs.filterRef.toggle();
      },
  
      onConfirmDateRange(date) {
        const [start, end] = date;
        this.showdateRange = false;
        this.dateRange = `${this.formatDate(start)}至${this.formatDate(end)}`;
      },
      formatDate(date) {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      },
  
      ChangeSorterType() {
        if (this.sorterType == 0) {
          this.sorterTypeTitleClass = ""
        }
        else {
          this.sorterTypeTitleClass = "dropdownhasvalueTitle"
        }
        this.page.sorterType = this.sorterType;
        this.onSearch();
      },
  
      onConfirmArea(values) {
        this.page.filterData.area = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
        this.showArea = false;
      },
  
  
      onRefresh() {
        this.onSearch();
        this.refreshing = false;
      },
      async initData() {
  
        let roleResult = await GetRoleType();
        if (roleResult.data.success) {
          this.roleType = roleResult.data.data;
        }
        else {
          this.roleType = 0;
        }
  
  
        this.page.sorterType = 0;
      },
  
  
      onSearch() {
        this.finished = false;
        this.dealers = [];
        this.page.current = 1;
        this.page.pageSize = 10;
        this.onLoad();
      },
      toDetail(id) {
  
        let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
        this.scroll = wrapperScrollTop;
  
        this.$router.push({
          path: "/dealer/campaigndetail",
          query: { id: id,back:true },
        });
      },
      async onLoad() {
        this.upLoading = true;
        let aresult = await PageCampaignStat({ ...this.page }); //({ ...this.page })
        this.dealers.push(...aresult.data.data);
        this.upLoading = false;
        if (this.page.current * this.page.pageSize < aresult.data.total) {
          this.page.current++;
        } else {
          this.finished = true;
        }
      },
      
      changeSorterType(typeid) {
        if (this.page.sorterType != typeid) {
          this.page.sorterType = typeid;
          this.onSearch();
        }
      },
    },
  };
  </script>
    
  <style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 90px;

  /deep/.van-list__loading {
    height: calc(80vh);
  }
}
  </style>
    